<template>
    <div class="systemPage">
        <div class="title-select">名城名镇名村综合管理平台</div>
        <div class="sub_title">（历史文化街区和历史建筑数据信息平台）</div>
        <div class="card-box">
            <div class="card-item" v-for="(item, index) in cards" :key="index">
                <div class="card-cont" v-if="item.iconfont" @click="handleClick(item.methodName)">
                    <div class="block" :class="{ active: item.list }">
                        <div class="img-box">
                            <i :class="item.iconfont"></i>
                        </div>
                        <div class="item-name">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="list" v-if="item.list">
                        <div
                            class="name"
                            v-for="listItem in item.list"
                            @click="handleClick(item.methodName, listItem)"
                        >
                            {{ item.name }}{{ listItem.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="dialogVisible" width="60vw"
            append-to-body style="margin-top: calc(20vh - 100px) !important;">
            <div class="dialog-body" style="height: 600px;border-radius: 10px; color: #1D3444;padding: 100px 12vw;">
                <p style="font-size: 36px;font-weight: bold;text-align: center;margin-bottom: 60px;">请注意！使用本账号请遵循以下准则：</p>
                <p><span class="round" style="background-color: #E9EDF2;">1</span> 本账号仅限主管部门内部使用，禁止外借账号。</p>
                <p><span class="round" style="background-color: #D3E7EB;">2</span> 本账号禁止在不同IP地址同时登录。</p>
                <p><span class="round" style="background-color: #C5CFD9;">3</span> 需要保证填报信息的真实性和完整性。</p>
                <p style="text-align: center;color: #C95C67;margin: 20px 0 -40px 250px;">如有违反，后果自负！</p>
                <p style="text-align: center;margin-top: 40px;">
                    <span @click="$router.push('/login')" class="index_btn">不同意</span>
                    <span @click="agreeHandle()" class="index_btn" :class="{ disabled: time != 0 }">{{ time ? `同意(${time}秒)`
                        :
                        '同意' }}</span>
                </p>
            </div>
        </el-dialog> -->
    </div>
</template>
<script>
import publicButton from "@/components/publicButton.vue";
import { mapState } from "vuex";

export default {
    components: {
        publicButton
    },
    computed: {
        ...mapState(["roleId", "protectList"])
    },
    watch: {
        roleId(val) {
            this.setMenu(val);
        }
    },
    data() {
        return {
            pageQuery: {
                pageIndex: 1,
                pageSize: 10,
                dateList: [],
                search: ""
            },
            cards: [],
            dialogVisible: false,
            time: 6
        };
    },
    methods: {
        // 按角色设置菜单
        setMenu(val) {
            if (val == 8) {
                // 文物局用户
                this.cards = [
                    {
                        iconfont: "iconfont icon-a-lianji9",
                        name: "名镇名村申报",
                        methodName: "enterSystem"
                    }
                ];
            } else if (val == 4) {
                // 专家用户
                this.cards = [
                    {
                        iconfont: "iconfont icon-a-lianji9",
                        name: "名镇名村申报",
                        methodName: "enterSystem"
                    },
                    {
                        iconfont: "iconfont icon-a-lianji10",
                        name: "名城规划审查",
                        methodName: "enterCityGHBZ",
                    }
                ];
            } else if (val == 10 || val == 11) {
                // 遥感中心用户角色 和名城分院用户
                this.cards = [
                    {
                        iconfont: "iconfont icon-a-lianji10",
                        name: "名城规划审查",
                        methodName: "enterCityGHBZ"
                    }
                ];
            } else {
                // 省市县、住建部用户
                this.cards = [
                    {
                        iconfont: "iconfont icon-a-lianji9",
                        name: "名镇名村申报",
                        methodName: "enterSystem"
                    },
                    {
                        iconfont: "iconfont icon-a-lianji10",
                        name: "规划审查",
                        methodName: "enterGHBZ",
                        list: this.protectList
                    },
                    {
                        iconfont: "iconfont icon-a-lianji11",
                        name: "名城体检评估",
                        methodName: "enterMCTJ"
                    },
                    {
                        iconfont: "iconfont icon-a-lianji12",
                        name: "五年划定",
                        methodName: "enterWNXT"
                    }
                ];
            }
        },
        agreeHandle() {
            if (this.time == 0) {
                this.dialogVisible = false;
            }
        },
        getSrc(filename) {
            return require(`../assets/image/${filename}`);
        },
        handleClick(methodName, item) {
            this.$apis.getMineInfo().then((res) => {
                this.$store.commit("setMineInfo", res.body);
                this.$store.commit(
                    "setNowTime",
                    this.$options.filters["dateformat"](res.now, "yyyy-MM-DD")
                );
                this[methodName]();
            });
            // 名城、名镇、名村区分
            if (item) {
                this.$store.commit("setTypeInfo", item);
            }
        },
        // 规划编制
        enterGHBZ() {
            this.$router.push(`/PlanningPrepare?key=infoOverview`);
        },
        // 名城规划审查
        enterCityGHBZ() {
            this.enterGHBZ();
            this.$store.commit("setTypeInfo", {
                text: "名城",
                name: "CITY",
                type: 1
            });
        },
        // 名城体检评估系统
        enterMCTJ() {
            let HREF = window.config.href.MCTJ;
            if (HREF) {
                window.location.href = HREF;
            }
        },
        // 五年划定系统
        enterWNXT() {
            window.location.href = "https://jqjz.nhcc.org.cn";
        },
        // 名镇名村
        enterSystem() {
            let route;
            if (this.roleId == 6) {
                // 市县用户
                route = "/ResourcesCensus/cityAndCounty/indexPage";
            }
            if (this.roleId == 5) {
                // 省级审核
                route = "/ResourcesCensus/province/indexPage";
            }
            if (this.roleId == 4) {
                // 部级专家用户
                route = "/ResourcesCensus/expert/indexPage";
            }
            if (this.roleId == 2 || this.roleId == 3 || this.roleId == 8) {
                // 部级用户or国家文物局用户
                route = "/ResourcesCensus/ministry/indexPage?key=baseInfoTown";
            }
            if (!route) {
                return this.$message.warning("该用户的角色没有菜单权限!");
            }
            this.$router.push(route);
        },
        reduceTime() {
            this.time--;
            if (this.time > 0) {
                setTimeout(() => {
                    this.reduceTime();
                }, 1000);
            }
        }
    },
    mounted() {
        this.setMenu(this.roleId);
        if (this.$route.query.code == "1") {
            this.dialogVisible = false;
        } else {
            this.dialogVisible = true;
        }
        // 登录成功，保存token
        this.$apis.getMineInfo().then((res) => {
            this.$store.commit("setMineInfo", res.body);
            this.$store.commit(
                "setNowTime",
                this.$options.filters["dateformat"](res.now, "yyyy-MM-DD")
            );
        });
        this.reduceTime();
    }
};
</script>
<style lang="less" scoped>
.systemPage {
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    left: 0;
    top: 0;
    // background: url('@/assets/image/index_bg.jpg');
    background: #f8fafc;
    background-size: 100vw 100vh;

    .title-select {
        font-size: 64px;
        text-align: center;
        margin-top: calc(50vh - 262px);
        color: #3f4f8d;
    }
    .sub_title {
        text-align: center;
        font-size: 32px;
        color: #3f4f8d;
    }

    .card-box {
        text-align: center;
        margin-top: 80px;

        .card-item {
            width: 240px;
            height: 360px;
            border-radius: 16px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 20px;
            cursor: pointer;
            overflow: hidden;
            background: rgba(182, 201, 211, 0.23);
            .card-cont {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;

                &:hover {
                    .block {
                        background: linear-gradient(
                            180deg,
                            #cfdde7 0%,
                            #d9e4ec 42%,
                            #d9e4ec 67%,
                            #ced2ee 100%
                        );
                        box-shadow: 0px 4px 14px 2px rgba(123, 146, 209, 0.16);
                        .img-box {
                            color: #3f4f8d;
                        }
                        .item-name {
                            color: #3f4f8d;
                        }
                    }

                    .list {
                        transition: 0.3s;
                        transform: translateY(-100%);
                    }
                    .active {
                        opacity: 0;
                    }
                }
                .block {
                    width: 100%;
                    height: 100%;
                    display: inline-block;

                    .img-box {
                        width: 104px;
                        height: 104px;
                        text-align: center;
                        // background-color: #F0F0F0;
                        margin: 137px auto 0;
                        color: #7684bc;
                        i {
                            font-size: 104px;
                        }
                    }

                    .item-name {
                        font-size: 24px;
                        color: #7684bc;
                        margin: 18px 0;
                    }
                }
                .list {
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background: rgba(182, 201, 211, 0.23);
                    height: -webkit-fill-available;
                    display: grid;
                    grid-template-rows: 1fr 1fr 1fr;
                    color: #7684bc;
                    font-size: 24px;
                    .name {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            color: #3f4f8d;
                            background: linear-gradient(
                                180deg,
                                #cfdde7 0%,
                                #d9e4ec 42%,
                                #d9e4ec 67%,
                                #ced2ee 100%
                            );
                            box-shadow: 0px 4px 14px 2px rgba(123, 146, 209, 0.16);
                        }
                    }
                }
            }

            // .item-footer {
            //     .in-btn {
            //         display: inline-block;
            //         width: 126px;
            //         height: 45px;
            //         line-height: 45px;
            //         border-radius: 22.5px;
            //         color: #1d3444;
            //         background-color: #f0f3f5;
            //         cursor: pointer;

            //         &:hover {
            //             color: #ffffff;
            //             background-color: #415767;
            //         }
            //     }
            // }
        }
    }
}
</style>
